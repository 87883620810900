"use client";

import { useAuth } from "@clerk/nextjs";
import { useEffect, useState } from "react";

export default function CreditsInfo() {
  const [used, setUsed] = useState<number | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const { userId } = useAuth();

  useEffect(() => {
    if (!userId) {
      setUsed(10);
      setTotal(10);
      return;
    }

    async function getCredits() {
      const res = await fetch("/api/credits", {
        method: "HEAD",
      });

      const used =
        Number.parseInt(res.headers.get("x-tldrapi-tokens-used")! ?? "0") ?? 0;
      const total =
        Number.parseInt(res.headers.get("x-tldrapi-tokens-total")! ?? "10") ??
        10;

      setUsed(used);
      setTotal(total);
    }

    getCredits();
  }, [userId]);

  return typeof used === "number" && typeof total === "number" ? (
    <>
      Credits left: {total - used}/{total}
    </>
  ) : null;
}
